import React from "react"
import Layout from "../components/Layout"
import Copyright from "../components/Copyright"

const Thanks = ({ data, pageContext }) => {
  return (
    <Layout>
      <div class="wrapper" id="app">
        <header class="headerContainer" data-scroll-section>
          <div class="headerContainer__logo">
            <a href="/">
              <img src="/logo.svg" alt="株式会社ブイ・フォース / vforce" />
            </a>
          </div>
        </header>
        <section class="page-header">
          <h2>Thanks</h2>
        </section>
        <main class="content">
          <section class="page-body flex">
            <div class="container center">
              <p>
                お問い合わせありがとうございます。<br />
                担当者から折り返し返信いたいます。
              </p>
              <a href="/" className="btn">
                Back Home
              </a>
            </div>
          </section>
        </main>
        <Copyright />
      </div>
    </Layout>
  )
}

export default Thanks